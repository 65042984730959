import { ApiError } from './ApiError';
import { AuthError } from './AuthError';
import { fetchApi } from './fetchApi';

export async function getCustomToken(): Promise<string> {
  if (!process.env.REACT_APP_FIREBASE_TOKEN_BASE_URL) {
    throw new Error('Firebase token url is not set');
  }
  if (!process.env.REACT_APP_FIREBASE_TOKEN_ENDPOINT) {
    throw new Error('Firebase token endpoint is not set');
  }
  try {
    const response = await fetchApi(
      process.env.REACT_APP_FIREBASE_TOKEN_ENDPOINT,
      {
        baseUrl: process.env.REACT_APP_FIREBASE_TOKEN_BASE_URL
      }
    );

    const {
      authentication_token: authToken
    }: { authentication_token: string } = await response.json();

    return authToken;
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.warn(
        'No custom token, if you need a dummy token make sure to start the CorePendium server'
      );
    }

    if (error instanceof ApiError) {
      // The fetch token endpoint will redirect to the HTML sign-in page if you
      // are not authenticated. Consider this an auth failure.
      if (error.status >= 300 && error.status < 400) {
        throw new AuthError(
          'Not authenticated. Redirected to sign in.',
          error.status
        );
      }

      // Not authorized
      if (error.status === 401) {
        throw new AuthError('Not authorized', 401);
      }

      throw error;
    }

    throw error;
  }
}
