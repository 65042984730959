import React, { useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import { SelectedFilters } from '@appbaseio/reactivesearch';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEqual } from 'lodash';

import ShowMoreList from './ShowMoreList';
import FreeContentToggle from './FreeContentToggle';
import DateFilter from '../../DateFilter';
import EMACallout from '../../Sidebar/EMACallout';
import SearchFilterItem from '../SearchFilterItem';
import SidebarSection from '../../Sidebar/SidebarSection';
import MultiList from './MultiList';
import EpisodeDateQuery from '../EpisodeDateQuery';
import { getEpisodeTypeDisplay } from '../../../lib/getEpisodeTypeDisplay';

import {
  setDateRange as setDateRangeAction,
  setCurrentTags as setCurrentTagsAction,
  setCurrentFaculty as setCurrentFacultyAction,
  setCurrentEpisodeType as setCurrentEpisodeTypeAction,
  setCurrentDocumentType as setCurrentDocumentTypeAction,
  toggleFilters as toggleFiltersAction
} from '../../../actions/filters';
import { reportFilterAppliedAnalytics } from '../../../analytics/reportFilterAppliedAnalytics';

import '../SearchFilters.scss';

// Work around https://github.com/appbaseio/reactivesearch/issues/1403
const defaultDocumentType = { forEach: () => 'test', length: 1, isFake: true };

export const MainSearchFilters = ({
  media,
  currentTags,
  currentFaculty,
  currentEpisodeType,
  currentDocumentType,
  currentSearch,
  setCurrentTags,
  setCurrentFaculty,
  setCurrentEpisodeType,
  setCurrentDocumentType,
  toggleFilters,
  filtersOpen,
  filtersVisible,
  dateRange,
  setDateRange
}) => {
  const [hasFirstClickHappened, setHasFirstClickHappened] = useState(false);
  const { modernizeTags } = useFlags();

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className="filters-container"
      onClick={() => {
        // Events get triggered twice by the library when the page loads.
        // This solution is to ignore those events until the user really clicks on the filters
        setHasFirstClickHappened(true);
      }}
    >
      {media.xs && filtersOpen && (
        <button type="button" className="backdrop" onClick={toggleFilters} />
      )}

      {filtersVisible && !media.xs && <EMACallout />}

      {filtersVisible && (
        <SidebarSection
          isTransparent
          className="sidebar-section--selected-filters"
        >
          <SelectedFilters
            clearAllLabel="Clear All"
            render={SearchFilterItem}
          />
        </SidebarSection>
      )}

      <SidebarSection>
        <MultiList
          componentId="episode-type-select"
          dataField="episode.episode_type.id"
          title="Episode Types:"
          filterLabel="Episode Types"
          defaultValue={currentEpisodeType}
          onValueChange={value => {
            if (isEqual(currentEpisodeType, value)) {
              return;
            }
            if (hasFirstClickHappened) {
              reportFilterAppliedAnalytics({
                filterType: 'episode type',
                filterValue: value.map(episodeType =>
                  getEpisodeTypeDisplay(parseInt(episodeType, 10))
                )
              });
            }
            setCurrentEpisodeType(value);
          }}
          showCheckbox={false}
          showSearch={false}
          renderItem={episodeType => getEpisodeTypeDisplay(episodeType)}
          showCount={false}
        />
      </SidebarSection>

      <SidebarSection>
        <FreeContentToggle />
      </SidebarSection>

      <SidebarSection>
        <DateFilter
          componentId="publish-date"
          dataField="episode.publish_date"
          placeholder="Date Range"
          title="Published Date:"
          filterLabel="Published Date"
          showHistogram={media.sm}
          snap
          URLParams
          dateRange={dateRange}
          setDateRange={setDateRange}
          react={{
            and: ['emrap-search-bar', 'episode-type-select']
          }}
        />
      </SidebarSection>

      <SidebarSection>
        <ShowMoreList
          componentId="faculty-select"
          dataField="chapter.media.md.faculty.raw"
          placeholder="Select faculty"
          title="Faculty:"
          filterLabel="Faculty"
          showCheckbox={false}
          showSearch={false}
          defaultValue={currentFaculty}
          onValueChange={value => {
            if (isEqual(currentFaculty, value)) {
              return;
            }
            if (hasFirstClickHappened) {
              reportFilterAppliedAnalytics({
                filterType: 'faculty',
                filterValue: value
              });
            }
            setCurrentFaculty(value);
          }}
          URLParams
          react={{
            and: ['emrap-search-bar', 'episode-type-select']
          }}
          customQuery={(value, props) => {
            if (value && value.length && !value.isFake) {
              return {
                query: {
                  terms: {
                    [props.dataField]: value
                  }
                }
              };
            }
            return {};
          }}
        />
      </SidebarSection>

      {!modernizeTags && (
        <SidebarSection>
          <ShowMoreList
            componentId="tag-select"
            dataField="chapter.tags.raw"
            placeholder="Select tags"
            showCheckbox={false}
            showSearch={false}
            title="Tags:"
            filterLabel="Tags"
            queryFormat="and"
            defaultValue={currentTags}
            onValueChange={value => {
              if (isEqual(currentTags, value)) {
                return;
              }
              if (hasFirstClickHappened) {
                reportFilterAppliedAnalytics({
                  filterType: 'tags',
                  filterValue: value
                });
              }
              setCurrentTags(value);
            }}
            URLParams
            react={{
              and: ['emrap-search-bar']
            }}
            customQuery={(value, props) => {
              if (value && value.length && !value.isFake) {
                return {
                  query: {
                    terms: {
                      [props.dataField]: value
                    }
                  }
                };
              }
              return {};
            }}
          />
        </SidebarSection>
      )}

      <SidebarSection>
        <MultiList
          className="multi-list--document-types"
          componentId="doc-type-select"
          dataField="type"
          title="Document Types:"
          filterLabel="Document Types"
          defaultValue={
            currentDocumentType && currentDocumentType.length
              ? currentDocumentType
              : defaultDocumentType
          }
          customQuery={(value, props) => {
            // Need to check value is defined because of
            // https://github.com/appbaseio/reactivesearch/issues/1403
            // Need to check isFake to work around
            // https://github.com/appbaseio/reactivesearch/issues/1402
            if (value && value.length && !value.isFake) {
              return {
                query: {
                  terms: {
                    [props.dataField]: value
                  }
                }
              };
            }

            return {
              query: {
                bool: {
                  must_not: {
                    term: {
                      type: 'comment'
                    }
                  }
                }
              }
            };
          }}
          onValueChange={value => {
            if (Array.isArray(value)) {
              if (isEqual(currentDocumentType, value)) {
                return;
              }
              if (hasFirstClickHappened) {
                reportFilterAppliedAnalytics({
                  filterType: 'document type',
                  filterValue: value
                });
              }
            }
            setCurrentDocumentType(value);
          }}
          react={{
            and: ['emrap-search-bar']
          }}
        />
      </SidebarSection>
      <EpisodeDateQuery
        currentSearch={currentSearch}
        currentDateRange={dateRange}
      />
    </div>
  );
};

const mapStatetoProps = state => ({
  filtersVisible: !!(
    state.filters.currentSearch ||
    state.filters.documentType ||
    state.filters.episodeType ||
    state.filters.faculty ||
    state.filters.tags ||
    state.filters.isFreeOnly
  ),
  filtersOpen: state.filters.open,
  currentTags: state.filters.tags,
  currentFaculty: state.filters.faculty,
  currentEpisodeType: state.filters.episodeType,
  currentDocumentType: state.filters.documentType,
  dateRange: state.filters.dateRange
});

const mapDispatchtoProps = dispatch =>
  bindActionCreators(
    {
      setCurrentTags: setCurrentTagsAction,
      setCurrentFaculty: setCurrentFacultyAction,
      setCurrentEpisodeType: setCurrentEpisodeTypeAction,
      setCurrentDocumentType: setCurrentDocumentTypeAction,
      setDateRange: setDateRangeAction,
      toggleFilters: toggleFiltersAction
    },
    dispatch
  );

const MainSearchFiltersContainer = connect(
  mapStatetoProps,
  mapDispatchtoProps
)(MainSearchFilters);

MainSearchFilters.propTypes = {
  media: PropTypes.shape({
    mobile: PropTypes.bool,
    desktop: PropTypes.bool,
    sm: PropTypes.bool,
    xs: PropTypes.bool
  }).isRequired,
  toggleFilters: PropTypes.func.isRequired,
  filtersOpen: PropTypes.bool.isRequired,
  filtersVisible: PropTypes.bool.isRequired,
  currentTags: PropTypes.arrayOf(PropTypes.string),
  currentFaculty: PropTypes.arrayOf(PropTypes.string),
  currentEpisodeType: PropTypes.arrayOf(PropTypes.string),
  currentDocumentType: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.shape({
      forEach: PropTypes.func,
      length: PropTypes.number,
      isFake: PropTypes.bool
    })
  ]),
  currentSearch: PropTypes.string,
  setCurrentTags: PropTypes.func.isRequired,
  setCurrentFaculty: PropTypes.func.isRequired,
  setCurrentEpisodeType: PropTypes.func.isRequired,
  setCurrentDocumentType: PropTypes.func.isRequired,
  dateRange: PropTypes.shape({
    start: PropTypes.number,
    end: PropTypes.number
  }).isRequired,
  setDateRange: PropTypes.func.isRequired,
  dataField: PropTypes.string.isRequired
};

MainSearchFilters.defaultProps = {
  currentTags: [],
  currentFaculty: [],
  currentEpisodeType: [],
  currentDocumentType: [],
  currentSearch: ''
};

export default MainSearchFiltersContainer;
