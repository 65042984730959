import { MutableRefObject, useEffect, useRef, useState } from 'react';
import {
  Timestamp,
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where
} from 'firebase/firestore';
import { debounce } from 'lodash';
import { db } from '../firebase';
import { authWithFirebase } from '../store/firebase';
import { store } from '../store';
import { useSelector } from '../lib/hooks';
import collections from '../firebase/collections';
import { Playback, playbackSchema } from '../schema/playback/playback';
import { reportError } from '../lib/reportError';
import { WebMedia } from '../schema/webEpisode/webMedia';
import { AuthError } from '../lib/AuthError';

async function updateFirebasePlayback(
  firebaseToken: string | null,
  playback: Playback
) {
  try {
    if (firebaseToken === null) {
      await store.dispatch(authWithFirebase());
    }
    await setDoc(
      doc(db, collections.playbacks, `${playback.account}_${playback.media}`),
      playback
    );
  } catch (error) {
    if (error instanceof AuthError) {
      // the user is signed out, we should not try to save the playback
      return;
    }
    reportError(error, {
      tags: {
        accountId: playback.account,
        mediaId: playback.media,
        collection: collections.playbacks
      }
    });
  }
}

const loadFirebasePlayback = async (
  firebaseToken: string | null,
  accountId: number,
  mediaId: number
) => {
  try {
    if (!firebaseToken) {
      await store.dispatch(authWithFirebase());
    }
    const querySnapshot = await getDocs(
      query(
        collection(db, collections.playbacks),
        where('media', '==', mediaId),
        where('account', '==', accountId)
      )
    );

    if (!querySnapshot.empty) {
      const data = querySnapshot.docs[0].data();
      try {
        return playbackSchema.parse(data);
      } catch (error) {
        reportError(error, {
          tags: {
            accountId,
            mediaId,
            collection: collections.playbacks
          }
        });
        return data as Playback;
      }
    }
  } catch (error) {
    if (error instanceof AuthError) {
      // the user is signed out, we should not try to load the playback
      return undefined;
    }
    reportError(error, {
      tags: {
        accountId,
        mediaId,
        collection: collections.playbacks
      }
    });
  }
  return undefined;
};

const syncFirebasePlayback = (
  seconds: number,
  highpoint: number,
  media: WebMedia,
  lastSavedPlaybackSeconds: MutableRefObject<{
    mediaId: number;
    seconds: number;
  }>,
  force: boolean,
  accountId: number,
  isEnded: boolean,
  firebaseToken: string | null
) => {
  if (
    // The parameter "force" is used to ignore the 10 seconds rule and always save.
    // Example of use: when the video/audio is paused and 10 seconds have not passed since the last save.
    force ||
    // The "seconds" stored in lastSavedPlaybackSeconds are only useful if they were saved for the current media.
    // This reference can be the same instance when switching chapters, so if the last save was during a previous chapter, it is ignored.
    lastSavedPlaybackSeconds.current.mediaId !== media.id ||
    Math.abs(lastSavedPlaybackSeconds.current.seconds - seconds) >= 10
  ) {
    const { duration } = media;
    const currentPointRounded = seconds >= duration - 15 ? duration : seconds;

    const playback = {
      account: accountId,
      currentpoint:
        isEnded || currentPointRounded === duration ? 0 : currentPointRounded,
      highpoint: Math.max(highpoint, currentPointRounded),
      media: media.id,
      last_played: Timestamp.now()
    };
    updateFirebasePlayback(firebaseToken, playback);

    // Update seconds saved since last saved playback
    lastSavedPlaybackSeconds.current = { mediaId: media.id, seconds };
  }
};

const debouncedSyncFirebasePlayback = debounce(syncFirebasePlayback, 500);

export function useFirebasePlayback(
  media: WebMedia,
  accountId: number | null | undefined,

  // Function to update the current position of the player (audio or video)
  playerSeek?: (seconds: number) => void
) {
  const firebaseState = useSelector(state => state.firebase);

  // It is the media id of the chapter we are skipping to, it is used to make the current playback point zero, if it equals the current media.
  const mediaIdToResetCurrentPoint = useRef<number | undefined>(undefined);

  // Local state that keeps track of the player's current time. It is only used in the audio player.
  const [secondsPlayed, setSecondsPlayed] = useState(0);
  const currentHighpointRef = useRef(0);

  // Keeps track of the last time the playback was saved in Firebase. Used to hold 10 seconds between each save.
  const lastSavedPlaybackSeconds = useRef({ mediaId: media.id, seconds: 0 });

  // Playback obtained from Firebase for the current media/account. It is loaded once and is used to set the start time of the video.
  const [firebasePlayback, setFirebasePlayback] = useState<
    Playback | undefined
  >();

  useEffect(() => {
    // Avoid setting states if the component was unmounted
    let mounted = true;
    if (accountId) {
      loadFirebasePlayback(firebaseState.token, accountId, media.id).then(
        playback => {
          if (mounted) {
            setFirebasePlayback(playback);
          }
        }
      );
    }
    return () => {
      mounted = false;
    };
  }, [media, accountId]);

  useEffect(() => {
    // Updates the initial state of the playback, based on the playback found in Firebase
    if (firebasePlayback?.media === media.id && playerSeek) {
      // Update local states and refs using the found playback
      const playedSeconds =
        mediaIdToResetCurrentPoint.current === media.id
          ? 0 // We are here after having skipped, reset the current playback time
          : firebasePlayback.currentpoint;
      mediaIdToResetCurrentPoint.current = undefined;
      currentHighpointRef.current = firebasePlayback.highpoint;
      setSecondsPlayed(playedSeconds);

      // Update the player position using the found playback
      // The react-player interpretates decimal numbers between 0 and 1 as a fraction of the total duration
      // of the media, so we don't want to send to it numbers of seconds like 0.7421213.
      playerSeek(Math.floor(playedSeconds));
    } else {
      // No playback for the current media was found in Firebase, no need to update the player position since it will start at 0 anyway
      currentHighpointRef.current = 0;
    }
  }, [firebasePlayback, playerSeek]);

  const synchronizeFirebasePlayback = (
    seconds: number,
    isEnded: boolean,
    isForced: boolean,
    debounced: boolean
  ) => {
    if (!accountId) {
      return;
    }

    // The debounced version of the function is only useful for the audio player to avoid saving many times when scrubbing (control bar)
    const syncFunction = debounced
      ? debouncedSyncFirebasePlayback
      : syncFirebasePlayback;
    currentHighpointRef.current = Math.max(
      currentHighpointRef.current,
      seconds
    );

    // the "syncFunction" (syncFirebasePlayback) may or may not save to Firebase depending on when was the last time we saved
    syncFunction(
      seconds,
      currentHighpointRef.current,
      media,
      lastSavedPlaybackSeconds,
      isForced,
      accountId,
      isEnded,
      firebaseState.token
    );
  };

  // This function should be called before we change the chapter/media
  const prepareMediaPlaybackReset = (mediaId: number | undefined) => {
    mediaIdToResetCurrentPoint.current = mediaId;
  };

  return {
    synchronizeFirebasePlayback,
    prepareMediaPlaybackReset,
    secondsPlayed,
    setSecondsPlayed,
    lastSavedPlaybackSeconds
  };
}
