import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { collection, getDocs, query, where } from 'firebase/firestore';
import isCacheValid from '../lib/isCacheValid';
import { AppState, EpisodeState } from '../lib/stateTypes';
import collections from '../firebase/collections';
import { WebEpisode, webEpisodeSchema } from '../schema/webEpisode/webEpisode';
import { db, auth } from '../firebase';
import formatWebEpisode from '../lib/formatWebEpisode';
import { hasPaidAndActiveSubscription } from '../lib/hasPaidAndActiveSubscription';
import { reportError } from '../lib/reportError';
import { isFirebaseOnline } from '../lib/isFirebaseOnline';
import { authWithFirebase } from './firebase';

const CACHE_LIFETIME_MS = 30000;

export const loadEpisode = createAsyncThunk<
  WebEpisode,
  string,
  {
    state: AppState;
    rejectValue: { error: string };
  }
>('episodes/loadEpisode', async (shortname: string, thunkAPI) => {
  const state = thunkAPI.getState();
  const errorContext = {
    tags: {
      signInWithCustomToken: true,
      loadEpisode: true,
      firestore: true,
      account: state.account?.account?.id,
      subscriptionType: state.account?.account?.subscription?.type
    },
    extra: {
      episodeShortname: shortname,
      account: state.account?.account?.id,
      subscriptionType: state.account?.account?.subscription?.type
    }
  };
  const episode = state.episodes.documents[shortname];
  if (
    episode &&
    episode.data &&
    isCacheValid(episode.lastUpdate ?? null, CACHE_LIFETIME_MS)
  ) {
    return episode.data;
  }

  let isSignedIn = auth.currentUser !== null;

  if (!isSignedIn) {
    await thunkAPI.dispatch(authWithFirebase());
    isSignedIn = auth.currentUser !== null;
  }

  try {
    const querySnapshot = await getDocs(
      query(
        collection(
          db,
          isSignedIn && hasPaidAndActiveSubscription(state.account)
            ? collections.episodes
            : collections.publicEpisodes
        ),
        where('shortname', '==', shortname)
      )
    );

    if (!querySnapshot.empty) {
      const data = querySnapshot.docs[0].data();
      const formattedData = formatWebEpisode(data);
      try {
        return webEpisodeSchema.parse(formattedData);
      } catch (error) {
        reportError(error, {
          tags: { ...errorContext.tags, zod: true },
          extra: { ...errorContext.extra, episodeId: data.id }
        });
        return formattedData as WebEpisode;
      }
    } else {
      // the getDoc request will always return a snapshot even if
      // the client is disconnected so we must check the
      // network status before we can be sure that the document
      // does not exist
      const firebaseOnline = await isFirebaseOnline();

      if (firebaseOnline) {
        // the query snapshot was empty so the episode does not exist
        return thunkAPI.rejectWithValue({
          error: 'episode does not exist'
        });
      }
      return thunkAPI.rejectWithValue({
        error: 'firebase is offline'
      });
    }
  } catch (error) {
    // Report the error to Sentry with detailed context so we can
    // match it up with other errors
    reportError(error, errorContext);
    if (error instanceof Error) {
      return thunkAPI.rejectWithValue({
        error: `${error.message}\n${error.stack}`
      });
    }
    throw error;
  }
});

const initialState: EpisodeState = { documents: {}, shortnames: {} };

export const episodesSlice = createSlice({
  name: 'episodes',
  initialState,
  reducers: {
    removeEpisode: (state: EpisodeState, action) => {
      const shortname = state.shortnames[action.payload.episodeId];
      delete state.documents[shortname];
    },
    updateEpisode: (state: EpisodeState, action) => {
      const { episodeId, data } = action.payload;
      const shortname = state.shortnames[episodeId];
      if (state.documents[shortname]) {
        state.documents[shortname] = {
          isLoading: false,
          data,
          hasError: false,
          error: undefined,
          lastUpdate: new Date()
        };
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(loadEpisode.pending, (state: EpisodeState, action) => {
      const shortname = action.meta.arg;
      if (!state.documents[shortname]) {
        state.documents[shortname] = {
          isLoading: true,
          data: undefined,
          hasError: false,
          error: undefined,
          lastUpdate: undefined
        };
      }
      if (!state.documents[shortname].isLoading) {
        state.documents[shortname].isLoading = true;
        state.documents[shortname].error = undefined;
        state.documents[shortname].hasError = false;
      }
    });
    builder.addCase(loadEpisode.rejected, (state, action) => {
      const shortname = action.meta.arg;
      state.documents[shortname] = {
        isLoading: false,
        data: undefined,
        hasError: true,
        error:
          action.payload?.error || action.error.message || 'unspecified error',
        lastUpdate: undefined
      };
    });
    builder.addCase(loadEpisode.fulfilled, (state: EpisodeState, action) => {
      const shortname = action.meta.arg;
      state.documents[shortname] = {
        isLoading: false,
        data: action.payload,
        hasError: false,
        error: undefined,
        lastUpdate: new Date()
      };
      state.shortnames[action.payload.id] = shortname;
    });
  }
});
