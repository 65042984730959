import { getDocs, query, collection, where } from 'firebase/firestore';
import { db } from '../firebase';
import { authWithFirebase } from '../store/firebase';
import { store } from '../store';
import collections from '../firebase/collections';
import { reportError } from './reportError';
import { Playback, playbackSchema } from '../schema/playback/playback';

const DEFAULT_BATCH_SIZE = 10;

export async function getPlaybackData(
  firebaseToken: string | null,
  chapterIds: number[],
  account: number,
  batchSize = DEFAULT_BATCH_SIZE
): Promise<Playback[]> {
  if (firebaseToken === null) {
    await store.dispatch(authWithFirebase());
  }

  const batchPromises = [];
  for (let i = 0; i < chapterIds.length; i += batchSize) {
    const batch = chapterIds.slice(i, i + batchSize);
    const firebaseQuery = query(
      collection(db, collections.playbacks),
      where('account', '==', account),
      where('media', 'in', batch)
    );
    batchPromises.push(getDocs(firebaseQuery));
  }

  const querySnapshots = await Promise.all(batchPromises);
  const allData = querySnapshots.flatMap(querySnapshot =>
    querySnapshot.docs.map(doc => doc.data() as Playback)
  );

  return allData.reduce<Playback[]>((accumulator, data) => {
    try {
      const validatedPlayback = playbackSchema.parse(data);
      return [...accumulator, validatedPlayback];
    } catch (error) {
      reportError(error, {
        tags: { media: data.media }
      });
    }
    return accumulator;
  }, []);
}
