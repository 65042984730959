import { signInWithCustomToken, UserCredential } from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { getCustomToken } from '../lib/getCustomToken';
import { FirebaseAuthState } from '../lib/stateTypes';
import { auth } from '../firebase/index';
import { reportError } from '../lib/reportError';
import { AuthError } from '../lib/AuthError';
import { retryWithBackoff } from '../lib/retryWithBackoff';
import { ApiError } from '../lib/ApiError';
import { MaximumRetryError } from '../lib/MaximumRetryError';
import { isOnline } from '../lib/isOnline';
import { isFirebaseOnline } from '../lib/isFirebaseOnline';

export const firebaseSlice = createSlice({
  name: 'firebase',
  initialState: {
    token: null,
    isLoading: false,
    error: undefined,
    unsubscribe: undefined,
    isCurator: false
  },
  reducers: {
    authStart: (state: FirebaseAuthState) => {
      state.token = null;
      state.isLoading = true;
      state.error = undefined;
      state.unsubscribe = undefined;
    },
    authSuccess: (state: FirebaseAuthState, action) => {
      state.token = action.payload.token;
      state.isLoading = false;
      state.error = undefined;
    },
    setIsCurator: (state: FirebaseAuthState, action) => {
      state.isCurator = action.payload;
    },
    authFailure: (state: FirebaseAuthState, action) => {
      state.token = null;
      state.isLoading = false;
      state.error = action.payload;
      // If a snapshot listener was set we must unsubscribe
      if (typeof state.unsubscribe === 'function') {
        state.unsubscribe();
      }
      state.unsubscribe = undefined;
      state.isCurator = false;
    },
    setUnsubscribe: (state: FirebaseAuthState, action) => {
      state.unsubscribe = action.payload;
    }
  }
});

export function authWithFirebase() {
  return async (dispatch: Dispatch) => {
    dispatch(firebaseSlice.actions.authStart());

    try {
      const customToken = await retryWithBackoff<string>(
        getCustomToken,
        (error: unknown) => {
          if (error instanceof AuthError) {
            throw error;
          }
          if (error instanceof ApiError) {
            if (error.status === 500) {
              throw error;
            }
          }
          reportError(error, {
            tags: {
              getCustomToken: true,
              authWithFirebase: true,
              firestore: true
            }
          });
          dispatch(firebaseSlice.actions.authFailure(error));
        }
      );

      await retryWithBackoff<UserCredential>(
        () => signInWithCustomToken(auth, customToken as string),
        (error: unknown) => {
          if (error instanceof FirebaseError) {
            if (
              error.code === 'auth/invalid-custom-token' ||
              error.code === 'auth/custom-token-mismatch'
            ) {
              throw error;
            }
          }
        }
      );

      dispatch(firebaseSlice.actions.authSuccess({ token: customToken }));
    } catch (error) {
      if (error instanceof AuthError) {
        // the user is just signed out, don't report
      } //
      else if (error instanceof FirebaseError) {
        if (
          error.code === 'auth/invalid-custom-token' ||
          error.code === 'auth/custom-token-mismatch'
        ) {
          reportError(error);
        }
      } //
      else if (error instanceof MaximumRetryError) {
        const online = await isOnline();
        let firebaseOnline = false;

        if (online) {
          try {
            firebaseOnline = await isFirebaseOnline();
          } catch (err) {
            reportError(err, {
              tags: { firebaseOnlineCheckFailed: true }
            });
          }
        }

        if (!online || !firebaseOnline) {
          reportError(new Error('Client is offline.'), {
            tags: {
              maximumRetries: true,
              LANOnline: online,
              firebaseOnline
            },
            extra: {
              retries: error.retries
            }
          });
        } else {
          reportError(error, {
            tags: { maximumRetries: true },
            extra: {
              retries: error.retries
            }
          });
        }
      } //
      else if (error instanceof Error) {
        reportError(error, {
          tags: {
            signInWithCustomToken: true,
            authWithFirebase: true,
            firestore: true
          }
        });
      } else {
        reportError(new Error('Unknown error'));
      }

      dispatch(firebaseSlice.actions.authFailure(error));
    }
  };
}
